import Vue from "vue";
import VueRouter from "vue-router";
//解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错的问题
const originalPush=VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject){
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

import store from '../store'
import {Message} from 'element-ui'
import crypto from "../common/crypto";
import isSameDay from "../common/isSameDay";
const routes = [
    {   
        path:'/',
        name:'root',
        redirect: '/default',
    },
    {
        path:'/default', 
        name:'default', 
        meta:{befarLoginAuth:true},
        redirect: '/home',
        component: () => import('../view/Default.vue'),
        children: [
            {
                path:'/home',
                name:'home',
                redirect: '/home/overview',
                component: () => import('../view/home/Entry.vue'),
                children: [
                    {
                        path:'overview',
                        name:'home-overview',
                        component: () => import('../view/home/Overview.vue'),
                    },
                    {
                        path:'graph',
                        name:'home-graph',
                        component: () => import('../view/home/Graph.vue'),
                    },
                ]
            },
            {
                path:'/targetAnalysis',//目标分析
                name:'targetAnalysis',
                meta:{moduleAuth:['mu_biao_fen_xi']},
                component: () => import('../view/targetAnalysis/Analysis.vue'),
            },
            {
                path:'/forecast',//预测模型
                name:'forecast',
                redirect: '/forecast/weekModel',
                component: () => import('../view/forecast/Entry.vue'),
                children: [
                    {
                        path:'weekModel',
                        name:'weekModel',
                        meta:{moduleAuth:['yu_ce_mo_xing']},
                        component: () => import('../view/forecast/WeekModel.vue'),
                    },
                    {
                        path:'monthModel',
                        name:'monthModel',
                        meta:{moduleAuth:['yu_ce_mo_xing']},
                        component: () => import('../view/forecast/MonthModel.vue'),
                    },
                ]
            },
            {
                path:'/forecastapply',//预测应用
                name:'forecastapply',
                redirect: ()=>{
                    let module=(store.state.loginRes.moduleList||[]).find((v)=>{return v.moduleCode=='yu_ce_ying_yong'}),
                        path=''
                    if(module&&module.children.length){
                        switch(module.children[0].moduleCode){
                            case 'bian_ji_bao_gao':
                                path='/forecastapply/reportview'
                            break;
                            case 'bao_gao_ku':
                                path='/forecastapply/allreport'
                            break;
                            case 'wo_de_yu_ce_tong_ji':
                                path='/forecastapply/forecastStatistics'
                            break;
                        }
                    }else{
                        path='/noAuth'
                    }
                    return path
                },
                component: () => import('../view/forecastapply/Entry.vue'),
                children: [
                    {
                        path:'/forecastapply/reportview',
                        name:'reportview',
                        meta:{moduleAuth:['yu_ce_ying_yong','bian_ji_bao_gao']},
                        component: () => import('../view/forecastapply/editReport.vue'),
                    },
                    {
                        path:'/forecastapply/allreport',
                        name:'allreport',
                        meta:{moduleAuth:['yu_ce_ying_yong','bao_gao_ku']},
                        component: () => import('../view/forecastapply/AllReport.vue'),
                    },
                    {
                        path:'/forecastapply/forecastStatistics',
                        name:'forecastStatistics',
                        meta:{moduleAuth:['yu_ce_ying_yong','wo_de_yu_ce_tong_ji']},
                        component: () => import('../view/forecastapply/ForecastStatistics.vue'),
                    },
                  
                ]
            },
            {
                path:'/customModel',//自定义模型
                name:'customModel',
                redirect: '/customModel/build',
                component: () => import('../view/customModel/Entry.vue'),
                children: [
                    {
                        path:'build',
                        name:'customModelBuild',
                        meta:{moduleAuth:['zi_ding_yi_mo_xing']},
                        component: () => import('../view/customModel/Build.vue'),
                    }
                ]
            }, 
            {
                path:'/results',//结果查看
                name:'results',
                redirect: '/results/forecastResults',
                component: () => import('../view/results/Entry.vue'),
                children: [
                    {
                        path:'forecastResults',
                        name:'forecastResults',
                        meta:{moduleAuth:['jie_guo_cha_kan']},
                        component: () => import('../view/results/ForecastResults.vue'),
                    },
                    {
                        path:'forecastScheme',
                        name:'forecastScheme',
                        meta:{moduleAuth:['jie_guo_cha_kan'],keepalive:true},
                        component: () => import('../view/results/ForecastScheme.vue'),
                    },
                    {
                        path:'modelComposition',
                        name:'modelComposition',
                        meta:{moduleAuth:['jie_guo_cha_kan']},
                        component: () => import('../view/results/ModelComposition.vue'),
                    },
                ]
            },
            {
                path:'/customModelDetail',//自定义模型详情
                name:'results',
                component: () => import('../view/customModelDetail/ModelDetail.vue'),
            },
            {
                path:'/customScene',//自定义模型情景模拟
                name:'results',
                component: () => import('../view/customModelDetail/Scene.vue'),
            },
            {
                path:'/forecastHistory',//我的预测方案历史查看
                name:'forecastHistory',
                component: () => import('../view/results/ForecastHistory.vue'),
            },
            {
                path:'/expert',//专家赋值
                name:'expert',
                redirect: '/expert/assignment/week',
                component: () => import('../view/expert/Entry.vue'),
                children: [
                    {
                        path:'assignment/:modelFreq',
                        name:'assignment',
                        meta:{moduleAuth:['zhuan_jia_fu_zhi']},
                        component: () => import('../view/expert/Assignment.vue'),
                    },
                ]
            },
            {
                path:'/outDivisors',//外生因子赋值
                name:'outDivisors',
                redirect: '/outDivisors/assignment/week',
                component: () => import('../view/outDivisors/Entry.vue'),
                children: [
                    {
                        path:'assignment/:modelFreq',
                        name:'outDivisorsAssignment',
                        meta:{moduleAuth:['wai_sheng_yin_zi_fu_zhi']},
                        component: () => import('../view/outDivisors/Assignment.vue'),
                    },
                ]
            },
            {
                path:'/modelManage',//模型管理
                name:'modelManage',
                redirect: '/modelManage/divisors',
                component: () => import('../view/modelManage/Entry.vue'),
                children: [
                    {
                        path:'divisors',
                        name:'divisorsPool',
                        meta:{moduleAuth:['mo_xing_guan_li']},
                        component: () => import('../view/modelManage/Divisors.vue'),
                    },
                    {
                        path:'modelTuning',
                        name:'modelTuning',
                        meta:{moduleAuth:['mo_xing_guan_li']},
                        component: () => import('../view/modelManage/ModelTuning.vue'),
                    },
                    {
                        path:'modelUpdate',
                        name:'modelUpdate',
                        meta:{moduleAuth:['mo_xing_guan_li']},
                        component: () => import('../view/modelManage/ModelUpdate.vue'),
                    },
                    {
                        path:'forecastUpdate',
                        name:'forecastUpdate',
                        meta:{moduleAuth:['mo_xing_guan_li']},
                        component: () => import('../view/modelManage/ForecastUpdate.vue'),
                    },
                ]
            },
            {
                path:'/datamanage',//数据管理
                name:'datamanage',
                redirect: '/datamanage/dataupload',
                component: () => import('../view/datamanage/Entry.vue'),
                children: [
                    {
                        path:'/datamanage/dataupload',
                        name:'dataupload',
                        meta:{moduleAuth:['shu_ju_guan_li']},
                        component: () => import('../view/datamanage/DataUpload.vue'),
                    },
                    {
                        path:'/datamanage/datauploadmanage',
                        name:'datauploadmanage',
                        meta:{moduleAuth:['shu_ju_guan_li']},
                        component: () => import('../view/datamanage/DataUploadManage.vue'),
                    },
                    {
                        path:'/datamanage/factormanage',
                        name:'factormanage',
                        meta:{moduleAuth:['shu_ju_guan_li']},
                        component: () => import('../view/datamanage/FactorManage.vue'),
                    },
                ]
            },
            {
                path:'/powermanage',//权限管理
                name:'powermanage',
                redirect: '/powermanage/userlist',
                component: () => import('../view/powerManage/Entry.vue'),
                children: [
                    {
                        path:'/powermanage/userlist',
                        name:'userlist',
                        meta:{moduleAuth:['quan_xian_guan_li']},
                        component: () => import('../view/powerManage/alluserList.vue'),
                    },
                    {
                        path:'/powermanage/editpowerforlist',
                        name:'editpowerforlist',
                        meta:{moduleAuth:['quan_xian_guan_li']},
                        component: () => import('../view/powerManage/editpowerforList.vue'),
                    },
                    {
                        path:'/powermanage/editpowerforuser',
                        name:'editpowerforuser',
                        meta:{moduleAuth:['quan_xian_guan_li']},
                        component: () => import('../view/powerManage/editpowerforUser.vue'),
                    },
                ]
            },
        ]
    },
    {
        path:'/forecastapply/preview',//报告预览
        name:'preview',
        meta:{befarLoginAuth:true,moduleAuth:['yu_ce_ying_yong','bian_ji_bao_gao']},
        component: () => import('../view/forecastapply/preView.vue'),
       
    },
    {
        path:'/forecastapply/reportshare',//报告分享
        name:'reportshare',
        meta:{befarLoginAuth:true,moduleAuth:['yu_ce_ying_yong','bao_gao_ku']},
        component: () => import('../view/forecastapply/reportShare.vue'),
       
    },
    {
        path:'/befarLogin',
        name:'befarLogin',
        component: () => import('../view/BefarLogin.vue')
    },
    {
        path:'/sciDefault', //卓创分析师用的管理页面
        name:'sciDefault', 
        meta:{sciLoginAuth:true},
        redirect: '/sciExpert',
        component: () => import('../view/sciManage/SciDefault.vue'),
        children: [
            {
                path:'/sciExpert',//专家赋值
                name:'sciExpert',
                redirect: '/sciExpert/assignment/week',
                component: () => import('../view/sciManage/expert/Entry.vue'),
                children: [
                    {
                        path:'assignment/:modelFreq',
                        name:'sciAssignment',
                        component: () => import('../view/sciManage/expert/Assignment.vue'),
                    },
                ]
            },
            {
                path:'/sciOutDivisors',//外生因子赋值
                name:'sciOutDivisors',
                redirect: '/sciOutDivisors/assignment/week',
                component: () => import('../view/sciManage/outDivisors/Entry.vue'),
                children: [
                    {
                        path:'assignment/:modelFreq',
                        name:'sciOutDivisorsAssignment',
                        component: () => import('../view/sciManage/outDivisors/Assignment.vue'),
                    },
                ]
            },
        ]
    },
    {
        path:'/login',
        name:'login',
        component: () => import('../view/Login.vue')
    },
    {
        path:'/sciLogin',
        name:'sciLogin',
        component: () => import('../view/SCILogin.vue')
    },
    {
        path:'/doc',
        name:'doc',
        component: () => import('../view/Document.vue')
    },
    {
        path:'/noAuth',
        name:'noauth',
        meta:{},
        component: () => import('../view/NoAuth.vue')
    },
    {
        path:'/error',
        name:'error',
        meta:{},
        component: () => import('../view/Error.vue')
    },
    {
        path:'/redirect',
        name:'redirect',
        component: () => import('../view/Redirect.vue')
    },
    {   
        //浏览器版本低提示
        path:'/browserVersionPrompt',
        name:'browserVersionPrompt',
        component: () => import('../view/BrowserVersionPrompt.vue')
    },
    {
        path:'*',
        name:'404',
        component: () => import( '../view/404.vue')
    }
]

const router = new VueRouter({
    routes
})
const styleObj = document.documentElement.style;
router.beforeEach((to, from, next) => {
    Message.closeAll()
    //从缓存取登录信息
    if(to.matched.some(record => record.meta.befarLoginAuth)&&!store.state.loginRes.token){
        try{    
            let loginRes=JSON.parse(crypto.decrypt(localStorage.getItem('loginResBefar')))||{}
            let loginStamp=localStorage.getItem('loginStampBefar')
            if(isSameDay(Number(loginStamp),new Date().getTime())){
                store.commit('loginRes',loginRes)
            }else{
                localStorage.removeItem('loginResBefar')
                localStorage.removeItem('loginStampBefar')
            }
        }catch{
           //
        }
    }
    if(to.matched.some(record => record.meta.sciLoginAuth)&&!store.state.loginResSCI.token){
        try{    
            let loginRes=JSON.parse(crypto.decrypt(localStorage.getItem('loginResSCI')))||{}
            let loginStamp=localStorage.getItem('loginStampSCI')
            if(isSameDay(Number(loginStamp),new Date().getTime())){
                store.commit('loginResSCI',loginRes)
            }else{
                localStorage.removeItem('loginResSCI')
                localStorage.removeItem('loginStampSCI')
            }
        }catch{
           //
        }
    }
    //刷新重新获取模块权限
    if(from.path=='/'&&to.matched.some(record => record.meta.befarLoginAuth)&&store.state.loginRes.token){
        Vue.prototype.$instance.get('/manage/getModulePower',{
            params: {
                ...store.state.basicParams
            },
        })
        .then(res=>{
            if(res.data.code==0){
                let data=res.data.info
                store.commit('loginRes',{...store.state.loginRes,moduleList:data})
                try{
                    localStorage.setItem('loginResBefar',crypto.encrypt(store.state.loginRes))
                }catch{
                //
                }
            }
        })
        .catch(()=> {});     
    }

    //判断浏览器是否支持html5=====================================================================================================================================
    if ((!('flex' in styleObj)||!('flexWrap' in styleObj))&&to.name!='browserVersionPrompt') {
        next({
            path: '/browserVersionPrompt',
        })
    }
    //滨化页面如未登录，需授权登录的页面跳转滨化登录
    else if(to.matched.some(record => record.meta.befarLoginAuth)&&!store.state.loginRes.token){
        if(store.state.loginByBefar){
            window.location.replace(`${Vue.prototype.$server}/login?state=${encodeURIComponent(to.fullPath)}`)
        }else{
            next({
                path: '/login',
                query:{
                    redirect:to.fullPath
                }
            })
        }
    }
    //卓创管理如未登录，需授权登录的页面跳转卓创用户登录页
    else if(to.matched.some(record => record.meta.sciLoginAuth)&&!store.state.loginResSCI.token){
        next({
            path: '/sciLogin',
            query:{
                redirect:to.fullPath
            }
        })
    }
    // check模块权限
    else if(to.meta.moduleAuth&&to.meta.moduleAuth.length){
        let module=store.state.loginRes.moduleList.find(v=>{return v.moduleCode==to.meta.moduleAuth[0]})
        if(module&&to.meta.moduleAuth.length>1){
            try{
                to.meta.moduleAuth.forEach((item,index)=>{
                    if(index>0){
                        module=module.children.find(v=>{return v.moduleCode==item})
                        if(!module){
                            throw Error
                        }
                    }
                })
            }catch{
                //
            }  
        }
        if(module){
            next()
        }else{
            next({
                path: '/noAuth',
                query:{}
            })
        }
    }
    else{
        next()
    }
  })

export default router;