import Vue from "vue";
import Vuex from 'vuex'
import "@/common/filter.js"//通用工具类js方法及filter过滤器
Vue.use(Vuex)
//store
const store = new Vuex.Store({
    state: {
      version:'1.0',
      theme:'light',//主题颜色

      loginByBefar:true,//是否用滨化账号体系登录

      basicParams:{},//接口公共参数
      loginRes:{},//滨化用户登录返回的信息
      loginResSCI:{},//卓创用户登录返回的信息
    },
    getters: {
       
    },
    mutations: {
        //state数据初始化
        stateInit(state){
            state.basicParams={}//接口公共参数
            state.loginRes={}//用户登录返回的信息
            state.loginResSCI={}//用户登录返回的信息
        },
        //用户信息
        loginRes (state,newval) {
            state.loginRes=newval
        },
        //卓创用户信息
        loginResSCI (state,newval) {
            state.loginResSCI=newval
        },
       
        //主题色
        theme (state,theme) {
            state.theme=theme 
        },
    },
    actions: {
        
    }
})

export default store