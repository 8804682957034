import Vue from 'vue'

//日期格式化
Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ?
            (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}
Vue.prototype.$dateFormat=(val,fmt,rv)=>{
    if(val){
        let dateArr=val.split(' '),
            formateDateArr=dateArr[0].replace(/-/g,"/").replace(/\./g,"/").split('/'),
            arr=new Array(3);
        arr[0]=formateDateArr[0]||'1970'
        arr[1]=formateDateArr[1]||'01'
        arr[2]=formateDateArr[2]||'01'
        let str=arr.join('/')+ (dateArr[1]?' '+dateArr[1]:'')
        return new Date(str).format(fmt)
    }else{
        return rv||''
    }
},
//数字格式化统一小数点后几位，小数点后位数不足的按原数返回
Vue.prototype.$numFormat=(number,num,rv)=> {
    let v=parseFloat(number),
        point_num=num||0    
    if(isNaN(v)){
        return rv||''
    }else if(v===0){
        return "0"
    }
    else{
        let v_str=String(number)
        let pointAfterLength=v_str.length-(v_str.indexOf(".")+1)
        if(pointAfterLength>point_num&&v_str.indexOf(".")>=0){
            return v.toFixed(point_num)
        }else{
            return String(v)
        }
    }
}
//数字千分位
Vue.prototype.$thousandBitSeparator=(number,num,rv)=> {
    //number数字，num格式化小数点位数，rv非数字时返回的站位符
    let v=parseFloat(number) 
    if(isNaN(v)){
        return rv||''
    }else if(v===0){
        return "0"
    }
    else{
        if(!isNaN(num)){
            let str=v.toFixed(num),
                arr=str.split('.');
            return parseInt(arr[0]).toLocaleString()+(arr[1]?'.'+arr[1]:'')
        }
       else{
        return v.toLocaleString()
       }
    }
}
//反转数组
Vue.prototype.$arrReverse=(arr)=>{
    let newArr= JSON.parse(JSON.stringify(arr||[])) 
    return newArr.reverse()
}
//对象数组根据某字段去重
Vue.prototype.$unique=(arr, val)=>{
    const res = new Map()
    return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1))
},
//vue过滤器=======================================================================================
//日期格式化过滤器
Vue.filter('dateFormat',(val,fmt,rv)=> {
    return Vue.prototype.$dateFormat(val,fmt,rv)
})
//数值千分位过滤器
Vue.filter('thousandBitSeparator',(number,num,rv)=> {
    return Vue.prototype.$thousandBitSeparator(number,num,rv)
})
//小数点位数格式过滤器
Vue.filter('numFormat',(number,num,rv)=> {
    return Vue.prototype.$numFormat(number,num,rv)
})
//数组反转过滤器
Vue.filter('arrReverse',(arr)=> {
    return Vue.prototype.$arrReverse(arr)
})