<template>
  <div id="app" ref="app">
    <router-view class="warp"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  mounted(){
    if(process.env.NODE_ENV!='production'){
      window.document.title=window.document.title+='（测试环境）'
    }

    this.theme()
    this.device()
    this.sizeLinstener()
    // 检测浏览器路由改变页面不刷新问题,hash模式的工作原理是 hashchange事件
    this.hashChangeLinstener()
  },
  methods: {
    theme:function(){
      try{
          localStorage.getItem('theme') && this.$store.commit('theme',localStorage.getItem('theme'))
      }catch{
          return
      }
      window.document.documentElement.setAttribute( "data-theme", this.$store.state.theme );
    },
    //设备类型
    device(){
      let userAgent=navigator.userAgent||'',
          isMobile=userAgent.indexOf('Mobile')>=0;
      window.document.documentElement.setAttribute( "data-device", isMobile?'mobile':'pc');
    },
    sizeLinstener(){
        this.$erd.listenTo({
            strategy: "scroll", //<- For ultra performance.
            callOnAdd: false,
            debug: false
        },this.$refs.app, ()=>{
            this.$root.docHeight=document.documentElement.clientHeight
            this.$root.docWidth=document.documentElement.clientWidth
        });
    },
    hashChangeLinstener(){
      window.addEventListener('hashchange', () => {
        let currentPath = window.location.hash.slice(1)
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath)
        }
      }, false)
    }
  }
}
</script>
<style lang="scss" scoped>
  .warp{
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
  }
</style>
<style lang="scss">
  @import './assets/css/common.scss';
  @import './assets/css/aside-nav.scss';
  @import './assets/css/resetElementUI.scss';
</style>