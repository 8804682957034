import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import ElementUI from 'element-ui';
import store from '@/store';
import router from '@/router';
Vue.use(VueAxios, axios)

//开发环境，接口地址
const server={
  production:"http://ppr.befar.com/pfapi",
  development:"https://idss.sci99.com/dev/befar/api",
  testing:"https://idss.sci99.com/dev/befar/api",
  befar:"http://ppr.befar.com/dev/pfapi",
  lz:'http://10.99.160.29:6032',
  gww:"http://10.99.160.34:6032",
  lx:"http://10.99.111.58:6032",
  yq:"http://10.99.111.56:6032",
  temp:"http://uat-xsjtai.ceic.com:8180/index"
}[process.env.NODE_ENV]

// 判断非本地server时，页面地址根据请求接口协议头自动判断是否转换https,自动执行函数
!function (){
  var reg = /^(10.99.)/,
      index=server.indexOf("//"),
      targetProtocol = server.substring(0,index);
  if(window.location.hostname !== 'localhost' && !reg.test(window.location.hostname) && window.location.protocol != "file:" && window.location.protocol != targetProtocol) {
    window.location.href = targetProtocol + window.location.href.substring(window.location.protocol.length);
  }
}()

//axios实例
const axiosInstance = axios.create({
  baseURL: server,
  timeout: 60000,
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  config => {
    if ((router.currentRoute.path=='/'&&store.state.loginRes.token)||(router.currentRoute.matched.some(record => record.meta.befarLoginAuth)&&store.state.loginRes.token)) {
      config.headers.token = store.state.loginRes.token
    }  else if (router.currentRoute.matched.some(record => record.meta.sciLoginAuth)&&store.state.loginResSCI.token) {
      config.headers.token = store.state.loginResSCI.token
    } 
    return config
  },
  error => Promise.error(error)
)

// 添加响应拦截器
axiosInstance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if(response.data.code!=0){
    ElementUI.Message({message: response.data.msg||'数据获取失败，请稍后再试',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
  }
  return response;
}, function (error) {
  // 对响应错误做点什么
  if(error.response&&error.response.status==401){
    if(router.currentRoute.matched.some(record => record.meta.befarLoginAuth)){
      try{
        localStorage.removeItem("loginRes");
        localStorage.removeItem("loginStamp");
      }catch{
        //
      }
      if(store.state.loginByBefar){
        window.location.replace(`${server}/login?state=${encodeURIComponent(router.currentRoute.fullPath)}`)
      }else{
      router.replace({
        path: '/login',
        query: { 
          status:401,
          redirect: router.currentRoute.fullPath
        }
      })
      }
    }else{
      try{
        localStorage.removeItem("loginResSCI");
        localStorage.removeItem("loginStampSCI");
      }catch{
        //
      }
      router.replace({
        path: '/sciLogin',
        query: { 
          status:401,
          redirect: router.currentRoute.fullPath
        }
      })
    }
  }else{
     ElementUI.Message({message: '数据获取失败，请稍后再试',type:'error',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
  }
  return Promise.reject(error);
});

export {
  server,
  axiosInstance,
}