import Vue from 'vue'
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ElementUI from 'element-ui';//element组件注册
import elementResizeDetectorMaker from 'element-resize-detector';//导入监听dom尺寸变化的侦听器
import router from '@/router'
import store from '@/store'
import qs from 'qs'
import {server,axiosInstance} from "@/axios"//axios全局配置
import App from '@/App.vue'
import * as echarts from 'echarts'; //导入echarts
import defaultChartOption from '@/common/defaultChartOption';//导入echart默认配置项参数
Vue.config.productionTip = false
Vue.use(ElementUI);

import 'font-awesome/css/font-awesome.min.css';//导入fontAwesome图标库
import '@/assets/css/element-variables.scss';//element-ui主题色

import "@/common/filter.js"//通用工具类js方法及filter过滤器

//vue原型挂载
Vue.prototype.$qs=qs//请求地址
Vue.prototype.$server=server//请求地址
Vue.prototype.$instance=axiosInstance//axios全局实例
Vue.prototype.$erd = elementResizeDetectorMaker();//监听dom尺寸变化方法
Vue.prototype.$echarts = echarts;//echarts实例
Vue.prototype.$defaultChartOption = defaultChartOption;//echarts默认options

Vue.directive('focus',{
  inserted:function(el){
    setTimeout(()=>{el.querySelector('input').focus()},200)
  }
})

//VUE实例
new Vue({
  router,
  store,
  data:{
    docHeight:document.documentElement.clientHeight,
    docWidth:document.documentElement.clientWidth
  },
  methods:{
    //路由跳转方法
    jump: function({path,query={},blank=false}){
      if(blank){
        let routeData = this.$router.resolve({ 
          path,
          query
        });
        window.open(routeData.href, '_blank');   
      }else{
        if(path == this.$route.path){
          let routeData = this.$router.resolve({ 
            path,
            query
          });
          this.$router.replace({
            path: '/redirect',
            query: {path:routeData.href.substring (1)}
          })
        }else {
          this.$router.push({
            path,
            query
          })
        }
      }
    },
  },
  render: h => h(App),
}).$mount('#app')

console.log("%c卓创资讯始终坚持中立第三方立场，客观、公正、科学的对大宗商品市场进行记录、观测及报道；https://www.sci99.com","color:blue;font-weight:bold")